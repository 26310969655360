@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}
body {
    position: static!important;
    min-height: 0!important;
}

@media (max-width: 1023px) {
    body {
        -webkit-user-select: none;  /* Safari */
        -ms-user-select: none;      /* IE 10 and IE 11 */
        user-select: none;          /* Standard syntax */
    }
}

.skiptranslate {
    display: none !important;
}

.goog-logo-link {
    display:none !important;
 }
 
 .goog-te-gadget {
    color: transparent !important;
 }
 
 .goog-te-gadget .goog-te-combo {
    color: blue !important;
 }

.goog-te-banner-frame {
    display: none!important;
}

.goog-text-highlight {
    background: none !important;
    box-shadow: none !important;
  }
  
  
  /* Cacher les éléments de l'UI de Google Translate qu'on ne veut pas voir */
  .goog-te-spinner-pos {
    z-index: 9999;
    position: fixed !important;
  }

  [class^="VIpgJd"],
  div[class^="VIpgJd"],
  span[class^="VIpgJd"],
  *[class^="VIpgJd"] {
    background-color: transparent !important;
    box-shadow: none !important;
    background: none !important;
    stroke: none !important;
    z-index: 0 !important;
  }